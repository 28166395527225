<template>
  <div>
    <v-row class="mt-10 px-5">
      <h2 class="mullerFont" style="font-size: 1.5rem">GÉNÉRATEUR DE MANDAT D'ÉTUDE</h2>
    </v-row>
    <v-divider class="my-3"></v-divider>
    <v-stepper class="my-2" v-model="e1"></v-stepper>
    <v-row>
      <v-col v-if="e1 > 2" cols="12" :md="e1 < 9 ? '2' : '12'" class="d-flex">
        <v-card class="flex d-flex flex-column" outlined>
          <v-progress-linear
            v-if="e1 <= 5 && e1 > 2"
            color="EoleBlue"
            height="5"
            :value="progressionPdfProgressBar"
            top
          ></v-progress-linear>
          <v-card-title>Mes étapes</v-card-title>
          <v-divider class="mx-5"></v-divider>
          <v-card-text class="flex-grow-1">
            <v-stepper v-model="e1" elevation="0">
              <v-stepper-header style="height: auto !important">
                <template>
                  <v-stepper-step
                    :key="`0-step`"
                    :color="e1 > 1 ? 'EoleYellow' : 'EoleBlueLighten'"
                    :complete="e1 > 1"
                    :step="1"
                    :rules="[() => this.steppersInformations[0].rule]"
                    editable
                  >
                    {{ this.steppersInformations[0].name }}<small>{{ this.steppersInformations[0].description }}</small>
                  </v-stepper-step>

                  <v-stepper-step
                    :key="`1-step`"
                    :color="e1 > 2 ? 'EoleYellow' : 'EoleBlueLighten'"
                    :complete="e1 > 2"
                    :step="2"
                    :rules="[() => this.steppersInformations[1].rule]"
                    editable
                  >
                    {{ this.steppersInformations[1].name }}<small>{{ this.steppersInformations[1].description }}</small>
                  </v-stepper-step>

                  <v-stepper-step
                    :key="`2-step`"
                    :color="e1 > 3 ? 'EoleYellow' : 'EoleBlueLighten'"
                    :complete="e1 > 3"
                    :step="3"
                    :rules="[() => this.steppersInformations[2].rule]"
                    editable
                  >
                    {{ this.steppersInformations[2].name }}<small>{{ this.steppersInformations[2].description }}</small>
                  </v-stepper-step>

                  <v-stepper-step
                    :key="`3-step`"
                    :color="e1 > 4 ? 'EoleYellow' : 'EoleBlueLighten'"
                    :complete="e1 > 4"
                    :step="4"
                    :rules="[() => this.steppersInformations[3].rule]"
                    editable
                  >
                    {{ this.steppersInformations[3].name }}<small>{{ this.steppersInformations[3].description }}</small>
                  </v-stepper-step>

                  <v-stepper-step
                    :key="`4-step`"
                    :color="e1 > 5 ? 'EoleYellow' : 'EoleBlueLighten'"
                    :complete="e1 > 5"
                    :step="4"
                    :rules="[() => this.steppersInformations[4].rule]"
                    editable
                  >
                    {{ this.steppersInformations[4].name }}<small>{{ this.steppersInformations[4].description }}</small>
                  </v-stepper-step>

                  <v-stepper-step :key="`7-step`" :step="5" complete edit-icon="mdi-eye" complete-icon="mdi-eye">
                    {{ this.steppersInformations[5].name }}<small>{{ this.steppersInformations[5].description }}</small>
                  </v-stepper-step>
                </template>
              </v-stepper-header>
            </v-stepper>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col v-show="e1 < 9" cols="12" :md="e1 > 2 ? '9' : '12'">
        <v-stepper class="transparent-bg" v-model="e1" flat>
          <v-stepper-content :step="1" class="text-center transparent-bg">
            <v-card-title class="justify-center" style="color: #fcc03c !important"
              >Choix du type de mandat</v-card-title
            >
            <v-card-subtitle>Pour commencer, veuillez choisir quel type de mandat vous souhaitez. </v-card-subtitle>
            <v-row class="text-center align-center d-inline-flex row-study mb-5 mt-5">
              <v-col cols="12" md="6" v-for="item in hoverStudyChoice" v-bind:key="item.name">
                <v-card
                  @mouseover="item.hovered = true"
                  @mouseleave="item.hovered = false"
                  v-on:click="chooseMandat(item.value)"
                  outlined
                  width="200"
                  height="200"
                  elevation="3"
                  class="align-center d-flex rounded-xl"
                >
                  <v-card-text class="text-center pa-5">
                    <i
                      :class="['fa-solid ', item.icon, 'fa-5x', item.hovered ? 'EoleYellow--text' : 'EoleBlue--text']"
                    ></i>

                    <p class="mt-5">{{ item.name }}</p>
                  </v-card-text>
                </v-card>
              </v-col>
            
            </v-row>
           
          </v-stepper-content>
          <v-stepper-content :step="2" class="text-center transparent-bg">
            <v-row class="pa-5">
              <v-btn @click="lastStep(2)" elevation="0" color="transparent">
                <v-icon left> mdi-chevron-left </v-icon>
                <span>Précédent</span>
              </v-btn>
            </v-row>
            <v-card-title class="justify-center" style="color: #fcc03c !important">
              Choix de la typologie de votre client</v-card-title
            >
            <v-card-subtitle class="text-center"
              >Bien, maintenant passons au choix de la typologie de votre client.</v-card-subtitle
            >
            <v-row class="text-center align-center row-study d-inline-flex mb-5">
              <v-col cols="12" md="6" v-for="item in typologyChoice" v-bind:key="item.name">
                <v-card
                  @mouseover="item.hovered = true"
                  @mouseleave="item.hovered = false"
                  v-on:click="chooseTypology(item.name)"
                  outlined
                  width="200"
                  height="200"
                  elevation="3"
                  class="align-center d-flex rounded-xl"
                >
                  <v-card-text class="text-center pa-5">
                    <i
                      :class="['fa-solid', item.icon, 'fa-5x', item.hovered ? 'EoleYellow--text' : 'EoleBlue--text']"
                    ></i>
                    <p class="mt-5">{{ item.name }}</p>
                  </v-card-text>
                </v-card>
              </v-col>
            
            </v-row>
          </v-stepper-content>
          <v-stepper-items v-if="this.radios === 'Particulier'">
            <v-stepper-content :step="3" class="no-padding">
              <v-card outlined>
                <v-card-title class="EoleBlue" style="color: white !important; font-size: 1rem">
                  <v-icon class="mr-2" color="white">mdi-account</v-icon> Informations personnelles
                </v-card-title>

                <v-card-text class="pa-5">
                  <v-form ref="formClientInformations" v-model="validityFormClientInformations" lazy-validation>
                    <v-row>
                      <v-col cols="12" md="2">
                        <v-select
                          dense
                          :rules="civilityRules"
                          :items="genderItems"
                          outlined
                          v-model="genderClient"
                          label="Civilité"
                          prepend-inner-icon="mdi-gender-male-female"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="2">
                        <v-text-field
                          dense
                          :rules="firstnameRules"
                          outlined
                          v-model="firstnameClient"
                          label="Prénom"
                          prepend-inner-icon="mdi-account"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="2">
                        <v-text-field
                          dense
                          :rules="lastnameRules"
                          outlined
                          v-model="lastnameClient"
                          label="Nom"
                          prepend-inner-icon="mdi-account"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field
                          dense
                          :rules="emailRules"
                          outlined
                          v-model="emailClient"
                          label="Email"
                          prepend-inner-icon="mdi-account"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="3">
                        <v-autocomplete
                          :loading="isLoading"
                          :search-input.sync="searchCity"
                          :items="cityItems"
                          outlined
                          v-model="cityClient"
                          :value="zipClient"
                          label="Commune"
                          clearable
                          hide-details
                          hide-selected
                          prepend-inner-icon="mdi-home"
                          dense
                          no-filter
                        >
                          <template v-slot:no-data>
                            <v-list-item>
                              <v-list-item-title> Rechercher une ville... </v-list-item-title>
                            </v-list-item>
                          </template>

                          <template v-slot:item="{ item }">
                            <v-list-item-content>
                              <v-list-item-title>{{ item }}</v-list-item-title>
                            </v-list-item-content>
                          </template></v-autocomplete
                        >
                      </v-col>

                      <v-col cols="12" md="3">
                        <v-text-field
                          dense
                          :rules="addressRules"
                          outlined
                          v-model="streetClient"
                          label="Adresse complète"
                          prepend-inner-icon="mdi-map-marker"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
                <v-card-text></v-card-text>
                <v-spacer></v-spacer>
                <v-divider></v-divider>

                <v-card-actions>
                  <v-btn @click="lastStep(2)" elevation="0">
                    <v-icon left> mdi-chevron-left </v-icon>
                    <span>Précédent</span>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn @click="validateFormClientInformations()" elevation="0">
                    <span>Continuer</span>
                    <v-icon right> mdi-chevron-right </v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>
          </v-stepper-items>

          <v-stepper-items v-else>
            <v-stepper-content :step="3">
              <v-card outlined elevation="0" class="overflow-hidden justify-center" min-height="100%">
                <v-card-title class="EoleBlueLighten">
                  <v-icon class="mr-2">mdi-office-building</v-icon> Renseignement de société
                </v-card-title>

                <v-card-text v-if="dataPappers === false" class="pa-5">
                  <loading
                    transition="fade"
                    :active.sync="loadingData"
                    :can-cancel="false"
                    :is-full-page="false"
                    color="#efb639"
                    :opacity="0.9"
                  >
                  </loading>

                  <v-text-field
                    :error="siretNotFound"
                    :error-messages="siretNotFoundMessage"
                    type="number"
                    counter="14"
                    outlined
                    v-model="siret"
                    label="Entrez votre n° de siret"
                    prepend-inner-icon="mdi-briefcase"
                  >
                  </v-text-field>
                </v-card-text>
                <v-card-text v-if="dataPappers" class="pa-5">
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        outlined
                        v-model="pappersInformations[0].nom_entreprise"
                        label="Raison sociale"
                        prepend-inner-icon="mdi-briefcase"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        outlined
                        v-model="pappersInformations[0].siege.siret"
                        label="Siret"
                        prepend-inner-icon="mdi-briefcase"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="4">
                      <v-text-field
                        outlined
                        v-model="pappersInformations[0].siege.code_postal"
                        label="Code Postale"
                        prepend-inner-icon="mdi-map-marker"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="4">
                      <v-text-field
                        outlined
                        v-model="pappersInformations[0].siege.adresse_ligne_1"
                        label="Addresse"
                        prepend-inner-icon="mdi-map-marker"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        outlined
                        v-model="pappersInformations[0].siege.ville"
                        label="Ville"
                        prepend-inner-icon="mdi-map-marker"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-divider class="mt-2 mx-10"></v-divider>
                </v-card-text>
                <v-card-title v-if="dataPappers" class="EoleBlueLighten">
                  <v-icon class="mr-2">mdi-information</v-icon> Description de l'activité de
                  {{ pappersInformations[0].nom_entreprise }}
                </v-card-title>

                <v-card-text v-if="dataPappers" class="pa-5">
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        outlined
                        v-model="pappersInformations[0].siege.code_naf"
                        label="Code NAF/APE"
                        prepend-inner-icon="mdi-briefcase"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        outlined
                        v-model="pappersInformations[0].siege.libelle_code_naf"
                        label="Description de l'activité"
                        prepend-inner-icon="mdi-briefcase"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-divider class="mt-2 mx-10"></v-divider>
                </v-card-text>

                <v-card-title v-if="dataPappers" class="EoleBlueLighten">
                  <v-icon class="mr-2">mdi-account-tie</v-icon> A propos des dirigeants
                </v-card-title>

                <v-card-text v-if="dataPappers" class="pa-5">
                  <v-row>
                    <v-col cols="12" md="4"></v-col>
                    <v-col cols="12" md="6"></v-col>
                    <v-col class="text-right" cols="12" md="2">Signataire du document :</v-col>
                  </v-row>
                  <v-row v-for="director in pappersInformations[0].representants" v-bind:key="director.nom">
                    <v-col cols="12" md="5">
                      <v-text-field
                        outlined
                        v-model="director.nom"
                        label="Nom"
                        prepend-inner-icon="mdi-account"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="5">
                      <v-text-field
                        outlined
                        v-model="director.prenom"
                        label="Prénom"
                        prepend-inner-icon="mdi-account"
                      ></v-text-field>
                    </v-col>

                    <v-col style="display: flex; justify-content: flex-end !important" cols="12" md="2">
                      <v-checkbox
                        v-model="signatory"
                        :value="{
                          civility: director.sexe === 'M' ? 'M' : 'Mme',
                          name: director.nom + ' ' + director.prenom_usuel,
                        }"
                        :key="director.nom"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                  {{ signatory }}
                </v-card-text>
              </v-card>
              <v-divider class="mb-5"></v-divider>

              <v-btn class="mr-5" color="EoleYellow" dark @click="lastStep(3)">
                <v-icon dark left> mdi-arrow-left </v-icon>RETOUR
              </v-btn>

              <v-btn color="primary" @click="dataPappers === true ? goToStep4() : goToStep2()"> Suivant </v-btn>
            </v-stepper-content>
          </v-stepper-items>
          <v-stepper-items>
            <v-stepper-content :step="4" class="no-padding">
              <v-card outlined>
                <v-card-title class="EoleBlue" style="color: white !important; font-size: 1rem">
                  <v-icon class="mr-2" color="white">mdi-account</v-icon> Quel(s) produit(s) concerné(s) ?
                </v-card-title>

                <v-row class="text-center my-5 pa-5">
                  <v-col cols="12" md="2" v-for="(product, index) in productsArray" v-bind:key="product.name">
                    <v-card
                      v-on:click="addProduct(product)"
                      flat
                      outlined
                      width="200"
                      height="200"
                      class="align-center"
                    >
                      <v-card-text class="text-center pa-5">
                        <i
                          :class="[
                            'fa-solid',
                            `fa-${product.icon} fa-3x`,
                            productNameList.includes(product) ? 'EoleYellow--text' : 'EoleBlue--text',
                          ]"
                        ></i>
                      </v-card-text>
                      <v-card-text>
                        <p :class="['mt-5', productNameList.includes(product) ? 'EoleYellow--text' : 'EoleBlue--text']">
                          {{ product.name.toUpperCase() }}
                        </p>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-btn @click="lastStep(4)" elevation="0">
                    <v-icon left> mdi-chevron-left </v-icon>
                    <span>Précédent</span>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    @click="
                      () => {
                        fetchInsurersByProductName(), nextStep(4)
                      }
                    "
                    elevation="0"
                  >
                    <span>Continuer</span>
                    <v-icon right> mdi-chevron-right </v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>
          </v-stepper-items>
          <v-stepper-items>
            <v-stepper-content :step="5" class="no-padding">
              <v-card outlined>
                <v-card-title class="EoleBlue" style="color: white !important; font-size: 1rem">
                  <v-icon class="mr-2" color="white">mdi-account</v-icon> Les compagnies avec lesquelles nous
                  travaillons pour le(s) produit(s) sélectionné :
                </v-card-title>

                <v-card-text class="pa-5">
                  <v-row>
                    <v-col
                      class="d-flex"
                      v-for="(insurer, index) in insurerSelected"
                      :key="insurer.id"
                      cols="12"
                      md="3"
                    >
                      <v-card outlined elevation="2" width="100%">
                        <v-row class="mt-1">
                          <v-col cols="12" md="9" class="text-left"></v-col>
                          <v-col cols="12" md="3" class="text-center">
                            <v-tooltip right>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" @click="deleteInsurer(index)" color="#B00020"
                                  >mdi-close-circle</v-icon
                                >
                              </template>
                              <span>Retirer {{ insurer.name }}</span>
                            </v-tooltip>
                          </v-col>
                        </v-row>
                        <v-card-title class="mullerFont justify-center">{{ insurer.name }} </v-card-title>
                        <v-card-text class="flex-grow-1 pa-2 justify-center text-center">
                          <v-img
                            alt="img"
                            class="mx-auto"
                            max-height="80"
                            max-width="80"
                            :src="`https://logo.clearbit.com/https://${insurer.website_url}?size=128`"
                            @error="showErrorIcon(insurer)"
                          ></v-img>
                          <v-icon v-if="insurer.showErrorIcon" size="80"> mdi-image-off </v-icon>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-dialog v-model="dialog_assurance" max-width="800px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-card
                            v-on:click="dialogAdd = true"
                            outlined
                            v-bind="attrs"
                            v-on="on"
                            height="100%"
                            class="d-flex align-center"
                          >
                            <v-card-text class="pa-5">
                              <v-row class="text-center">
                                <v-col cols="12" md="12">
                                  <v-icon color="EoleBlue" size="40">mdi-plus-circle</v-icon>
                                </v-col>
                                <v-col cols="12" md="12">
                                  <h3>Ajouter une compagnie</h3>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </template>
                        <v-card>
                          <v-card-title>
                            <span class="text-h5">Sélectionné le(s) assureur(s) concerné(s)</span>
                          </v-card-title>
                          <v-card-text class="no-padding">
                            <v-card-title>
                              <v-text-field
                                dense
                                v-model="searchCompany"
                                outlined
                                append-icon="mdi-magnify"
                                label="Recherche"
                                single-line
                                hide-details
                              ></v-text-field>
                            </v-card-title>
                            <v-spacer></v-spacer>

                            <v-data-table
                              dense
                              v-model="insurerSelected"
                              :headers="headersCompany"
                              :items="insurersNameForProduct"
                              item-key="name"
                              :search="searchCompany"
                              :page.sync="pageCompany"
                              show-select
                              hide-default-footer
                              no-results-text="Aucune compagnie ne correspond à votre recherche"
                              checkbox-color="#52b788"
                              @page-count="pageCountCompany = $event"
                            >
                              <template v-slot:[`item.website_url`]="{ item }">
                                <v-img
                                  class="mx-auto"
                                  max-height="32"
                                  max-width="32"
                                  :src="`https://logo.clearbit.com/https://${item.website_url}?size=128`"
                                  @error="showErrorIcon(item)"
                                ></v-img>
                                <v-icon class="mx-auto" v-if="item.showErrorIcon" size="32"> mdi-image-off</v-icon>
                              </template>
                            </v-data-table>
                            <div class="text-center pt-2">
                              <v-pagination v-model="pageCompany" :length="pageCountCompany"> </v-pagination>
                            </div>
                          </v-card-text>
                          <v-divider class="my-2"></v-divider>
                          <v-card-actions>
                            <v-btn color="blue darken-1" text @click="dialog_assurance = false"> Fermer </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn color="EoleGreen" @click="dialog_assurance = false" class="white--text"
                              >Enregistrer
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-card-title
                  v-if="this.mandatChoose === `ODR`"
                  class="EoleBlue"
                  style="color: white !important; font-size: 1rem"
                >
                  <v-icon class="mr-2" color="white">mdi-account</v-icon>Informations complémentaires
                </v-card-title>
                <v-card-text v-if="this.mandatChoose === `ODR`" class="pa-5">
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        outlined
                        v-model="numPolice"
                        label="Numéro de police"
                        type="number"
                        prepend-inner-icon="mdi-account"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        :rules="dateRules"
                        outlined
                        v-model="dateODR"
                        label="Date police"
                        data-provide="datepicker"
                        type="date"
                        prepend-inner-icon="mdi-calendar"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-divider></v-divider>

                <v-card-actions>
                  <v-btn @click="lastStep(5)" elevation="0">
                    <v-icon left> mdi-chevron-left </v-icon>
                    <span>Précédent</span>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    @click="
                      () => {
                        nextStep(5), fetchDataInsurer()
                      }
                    "
                    elevation="0"
                  >
                    <span>Continuer</span>
                    <v-icon right> mdi-chevron-right </v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>
          </v-stepper-items>

          <v-stepper-items>
            <v-stepper-content :step="6" class="no-padding">
              <v-card outlined>
                <v-card-title class="EoleBlue" style="color: white !important; font-size: 1rem">
                  <v-icon class="mr-2" color="white">mdi-file</v-icon> Formalisation des mandats
                </v-card-title>
                <v-card-text class="mt-5">
                  <v-row>
                
                  </v-row>
                  <v-row>
                    <v-col class="d-flex" v-for="insurer in studyItems" :key="insurer.id" cols="12" md="6">
                      <v-card outlined elevation="2" width="100%">
                        <v-card-title class="mullerFont justify-center">{{ insurer.name }} </v-card-title>
                        <v-card-text class="flex-grow-1 pa-2 justify-center text-center">
                          <v-img
                            alt="img"
                            class="mx-auto"
                            max-height="80"
                            max-width="80"
                            :src="`https://logo.clearbit.com/https://${insurer.url}?size=128`"
                            @error="showErrorIcon(insurer)"
                          ></v-img>
                          <v-icon v-if="insurer.showErrorIcon" size="80"> mdi-image-off </v-icon>
                        </v-card-text>
                        <v-card-text>
                          <iframe :id="insurer.name" style="width: 100%; height: 500px"> </iframe>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-row>
                        <v-col cols="12" md="12">
                          <v-card @click="testMerger" outlined flat class="text-center justify-center">
                            <v-card-title class="text-center justify-center">Télécharger le document </v-card-title>
                            <v-card-text> <i class="fa-solid fa-download fa-4x EoleBlue--text"></i> </v-card-text>
                          </v-card>
                        </v-col>
                        <v-col cols="12" md="12">
                          <v-dialog v-model="dialogEmail" persistent max-width="600px">
                            <template v-slot:activator="{ on, attrs }">
                              <v-card v-bind="attrs" v-on="on" outlined flat class="text-center justify-center">
                                <v-card-title class="text-center justify-center"
                                  >Envoyer le document par email</v-card-title
                                >
                                <v-card-text>
                                  <i class="fa-solid fa-envelope-open-text fa-4x EoleBlue--text"></i>
                                </v-card-text>
                              </v-card>
                            </template>
                            <v-card>
                              <v-card-title>
                                <span class="text-h5">Envoi de l'email</span>
                              </v-card-title>
                              <v-card-text>
                                <v-container>
                                  <v-row>
                                    <v-col cols="12" sm="6" md="8">
                                      <v-text-field
                                        v-model="emailClient"
                                        dense
                                        outlined
                                        label="Email client"
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col cols="12" md="6" class="mt-4">
                                      Souhaitez-vous êtes en copie de l'email ?
                                    </v-col>
                                    <v-col cols="12" md="6">
                                      <v-radio-group v-model="emailCopyInfo" row>
                                        <v-radio label="Oui" :value="true"></v-radio>
                                        <v-radio label="Non" :value="false"></v-radio>
                                      </v-radio-group>
                                    </v-col>
                                  </v-row>
                                  <v-row v-if="emailCopyInfo">
                                    <v-col cols="12" md="5">
                                      <v-text-field
                                        v-model="emailCopy"
                                        dense
                                        outlined
                                        label="Email en copie"
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <p>Contenu pièces jointes de l'email</p>
                                  </v-row>
                                  <v-row>
                                    <v-col cols="12" md="5">
                                      <ul v-for="item in studies" :key="item">
                                        <li>{{ item }}</li>
                                      </ul>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-card-text>
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="dialogEmail = false"> Fermer </v-btn>
                                <v-btn color="blue darken-1" text @click="MergeForEmail"> Envoyer </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-col>
                        <v-col cols="12" md="12">
                          <v-card @click="testMerger" outlined flat class="text-center justify-center">
                            <v-card-title class="text-center justify-center"
                              >Signer electroniquement le document
                            </v-card-title>
                            <v-card-text> <i class="fa-solid fa-download fa-4x EoleBlue--text"></i> </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from "vue"
import "@mdi/font/css/materialdesignicons.css" // Load icons
import config from "@/views/config/config"
import imageData from "@/views/config/imageData"
import pdfMake, { async } from "pdfmake/build/pdfmake"
import pdfFonts from "pdfmake/build/vfs_fonts"
import emailjs from "@emailjs/browser"
import { init } from "@emailjs/browser"
init("user_gUunL4KW2ImPaGngWHYhP")
// Import component
import Loading from "vue-loading-overlay"
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css"
pdfMake.vfs = pdfFonts.pdfMake.vfs
const PDFMerger = require("pdf-merger-js")
import Toast from "vue-toastification"
// Import the CSS or use your own!
import "vue-toastification/dist/index.css"
import moment from "moment"
moment.locale("fr")

var merger = new PDFMerger()
Vue.use(Toast, {
  maxToasts: 2,
  newestOnTop: true,
})

export default Vue.extend({
  name: "Home",

  data: () => ({
    typologyChoice: [
      {
        name: "PROFESSIONNEL",
        icon: "fa-building",
        hovered: false,
      },
      {
        name: "PARTICULIER",
        icon: "fa-people-arrows",
        hovered: false,
      },
    ],
    hoverStudyChoice: [
      {
        name: "MANDAT D'ÉTUDE",
        icon: "fa-file",
        value: "ME",
        hovered: false,
      },
      {
        name: "ORDRE DE REMPLACEMENT",
        icon: "fa-people-arrows",
        value: "ODR",

        hovered: false,
      },
      {
        name: "MANDAT D'ÉTUDE ET DE PLACEMENT",
        icon: "fa-file-arrow-up",
        value: "MEP",

        hovered: false,
      },
      {
        name: "MANDAT DE TRANSFERT D'AGENCE",
        icon: "fa-building-circle-arrow-right",
        value: "MTA",

        hovered: false,
      },
    ],
    searchCity: null,
    emailClient: null,

    isLoading: false,

    pageProduct: 1,
    pageCountProduct: 0,
    pageCompany: 1,
    pageCountCompany: 0,
    emailClient: "",
    studies: [],
    emailCopyInfo: false,
    emailCopy: "",
    dialogEmail: false,
    studyItems: [],
    dialog: false,
    dialog_assurance: false,
    singleSelect: false,
    productNameList: [],
    search: "",
    searchCompany: "",
    insurerChoose: [],
    arrayPDF: [],
    mandatChoose: "",
    numPolice: null,
    dateODR: moment().format("DD/MM/YYYY"),
    menu: false,

    headers: [
      {
        text: "Nom du produit",
        align: "start",
        sortable: false,
        value: "name",
      },
    ],

    headersCompany: [
      {
        text: "",
        align: "start",
        sortable: false,
        value: "website_url",
      },
      {
        text: "Nom de la compagnie",
        align: "start",
        sortable: false,
        value: "name",
      },
    ],

    //? GDPR MENTION
    personnalData: [
      "Données d’identité : prénoms, nom, titre, date de naissance et genre.",
      "Données de contact : adresse de facturation, adresse personnelle, adresse professionnelle, adresse e-mail et numéros de téléphone.",
      "Données financières : détails du compte et de la carte de paiement (nous utilisons ces données uniquement à des fins de traitement des paiements).",
      "Données techniques : adresse IP, données de connexion, données de trafic, type et version du navigateur, type et version du téléphone, paramètres et emplacement du fuseau horaire, types et versions du plug-in du navigateur, système d’exploitation et plateforme, et autres informations concernant la façon dont vous avez accédé à notre site web (où celui de nos partenaires) et notre application.",
      "Données d’utilisation : informations sur la lecture des propositions commerciales reçues, et la façon dont vous utilisez nos services.",
      "Données marketing et communication : vos préférences marketing et communication.",
    ],
    collectData: [
      "Des interactions directes : vous pouvez nous fournir des informations personnelles en remplissant des formulaires ou en nous contactant par courrier, téléphone, email, en personne lors d'événements, dans le cadre de notre accord contractuel avec vous, en saisissant ces données sur l'une de nos plateformes clients, etc. Cela inclut les données personnelles que vous fournissez lorsque :",
      "Vous commandez ou exprimez un intérêt pour nos produits ou services,",
      "Vous participez à un concours, une promotion ou un sondage,",
    ],
    reasonCollectData: [
      "Pour vérifier votre identité et vos droits à nos produits et services lorsque vous nous contactez ou accédez à nos services",
      "Pour vous protéger des fraudes",
      "Pour mener à bien nos obligations envers vous, reliées par notre contrat",
      "Pour vous envoyer des relevés et des factures et percevoir des paiements de votre part",
      "Pour vous fournir des devis commerciaux",
      "Pour exploiter et améliorer nos services",
      "Pour vous informer de tout changement apporté à notre activité (adresse, téléphones, horaires…)",
      "Pour vous fournir un support",
      "Pour obtenir votre avis ou vos commentaires sur nos services",
      "Pour faire respecter nos droits légaux ou se conformer aux exigences légales",
    ],
    useData: [
      "Lorsque nous devons exécuter le contrat que nous sommes sur le point de mettre en place ou de conclure avec vous.",
      "Lorsque nous devons nous conformer à une obligation légale ou réglementaire.",
    ],
    rightData: [
      "Un accès à vos informations personnelles",
      "De réclamer toute demande de modification ou correction de vos informations",
      "D’exiger la suppression des informations personnelles vous concernant dans certaines situations",
      "De recevoir les informations personnelles que vous nous avez fournies, dans un format structuré, communément utilisé et lisible par machine, et avoir le droit de transmettre ces données à un tiers dans certaines situations",
      "De s’opposer à tout moment au traitement des informations personnelles vous concernant pour le marketing direct",
      "De s’opposer aux décisions prises par des moyens automatisés (y compris le profilage)",
      "De s’opposer dans certaines au traitement continu de vos informations personnelles",
      "De limiter le traitement de vos informations personnelles dans certaines circonstances",
    ],

    shareData: [
      "Les fournisseurs que nous engageons pour proposer les services que nous offrons à nos clients (outils de signature électronique par exemple) ou pour proposer les garanties d’assurance conformes à vos besoins en votre nom. Ces sociétés sont basées en Europe.",
      "Des conseillers professionnels, y compris des avocats, des comptables des notaires… si nécessaire, pour leur permettre de nous fournir leurs services et leur accompagnement.",
      "Des régulateurs et autres autorités qui exigent la déclaration des activités dans certaines circonstances.",
    ],

    //? Product : Moto/Scooter
    arrayNull: [],
    licencesSelected: [],
    licences: [],

    otherAddress: false,
    otherStreetClient: "",
    otherZipClient: "",
    //? LDDC variables
    distance: 0,
    signatory: [],
    recommandAmount: 0,

    loadingAllGuaranteesSelected: false,
    gtest: [],
    allGuaranteesSelected: false,
    franchiseChoice: [],
    franchiseSelected: "",
    autoStepper: 1,

    companySelected: "",

    helpReason: "",
    reason: "",
    siretNotFound: false,
    siretNotFoundMessage: "",

    insurerSelected: [],
    zipClientOK: false,
    radioGroup: 1,
    valueRadioInsurer: "",
    show: false,
    insurersNameForProduct: [],
    resume: [],
    productName: "",
    productNameText: "",
    productNameList: [],

    // * General data *
    agentFor: null,
    siret: null,
    siretCounter: 0,
    dataPappers: false,
    pappersInformations: [],
    typologieClient: null,
    drawer: true,
    mini: true,

    btnNext: 0,

    userState: null,
    /* bad rules */

    productsArray: [],

    //? CLIENT INFORMATIONS :
    radios: null,
    mandatType: null,

    directorsNames: [],
    firstnameClient: null,
    lastnameClient: null,
    genderClient: "Monsieur",
    companyNameClient: null,
    siretClient: null,
    apeCodeClient: null,
    descriptionActivityClient: null,
    zipClient: "",
    streetClient: null,
    cityItems: [],
    genderItems: ["Monsieur", "Madame"],
    nationalityClient: null,
    addressClient: null,
    cityClient: null,

    //? RULES CONFIGURATION
    rulesInformation: true,
    validityFormClientInformations: false,

    //? RULES FOR STEP 2 - PRO
    //? RULES FOR STEP 2 - PART
    lastnameClientRules: false,
    firstnameClientRules: false,
    genderClientRules: false,
    cityRules: [v => !!v || "Une ville est requise"],
    addressRules: [v => !!v || "Une adresse valide est requise"],
    civilityRules: [v => !!v || "Civilité est requise"],
    lastnameRules: [v => !!v || "Nom requis"],
    firstnameRules: [v => !!v || "Prénom requis"],
    lastnameRules: [v => !!v || "Nom requis"],
    dateRules: [v => !!v || "Date requis"],
    emailRules: [v => !!v || "E-mail requis", v => /.+@.+\..+/.test(v) || "E-mail doit être valide"],

    //? SUCCESS MESSAGES - PART
    successSelectCity: false,
    successSelectCityMessage: null,
    errorSelectCity: false,
    errorSelectCityMessage: null,

    //? STEPPERS CONFIGURATION */
    e1: 1,
    e2: 1,
    steps2: 3,
    steps: 9,
    steppersInformations: [
      {
        name: "Étape  1",
        description: "Type de mandat",
        rule: true,
      },
      {
        name: "Étape  2",
        description: "Typologie client",
        rule: true,
      },
      {
        name: "Étape  3",
        description: "Informations client",
        rule: true,
      },
      {
        name: "Étape  4",
        description: "Choix de(s) produit(s)",
        rule: true,
      },
      {
        name: "Étape  5",
        description: "Compagnie(s) interrogé(s)",
        rule: true,
      },
      {
        name: "Finalisation",
        description: "Génération du document",
        rule: true,
      },
    ],
    progressionPdfProgressBar: 0,
    loader: null,
    loading: false,
    loadingData: false,
    /* -------------- */

    //? VALIDATION STEPPERS
    validationStep1: false,
    selection: 1,
    completeFinish: false,

    /*  --------------*/
  }),
  components: {
    Loading,
  },
  watch: {
    loader() {
      const l = this.loader
      this[l] = !this[l]

      setTimeout(() => (this[l] = false), 3000)

      this.loader = null
    },

    searchCity(val) {
      console.log(val)
      if (val.length === 5) {
        try {
          this.isLoading = true

          fetch("https://geo.api.gouv.fr/communes?codePostal=" + val)
            .then(res => res.clone().json())
            .then(res => {
              this.cityItems = res.map(item => item.nom)
              this.zipClient = val
            })
            .catch(err => {
              console.log(err)
            })
            .finally(() => (this.isLoading = false))

          // for (let index = 0; index < data.length; index++) {
          //   this.cityItems.push(data[index].nomCommune)
          // }

          this.successSelectCity = true
          this.successSelectCityMessage = `Nous avons trouvé ${data.length} villes ou communes ayant comme code postal ${val}`
        } catch (e) {
          this.errorSelectCity = true
          this.errorSelectCityMessage = "Une erreur est survenue"
        }
      } else {
        this.errorSelectCity = true
        this.errorSelectCityMessage = "Le code postal doit être composé de 5 chiffres"
      }

      // // Items have already been loaded
      // if (this.items.length > 0) return

      // this.isLoading = true

      // // Lazily load input items
      // fetch('https://api.coingecko.com/api/v3/coins/list')
      //   .then(res => res.clone().json())
      //   .then(res => {
      //     this.items = res
      //   })
      //   .catch(err => {
      //     console.log(err)
      //   })
      //   .finally(() => (this.isLoading = false))
    },

    otherAddress() {
      this.cityItems = []
    },
  },
  computed: {
    isDisabled() {
      if (this.zipClient.length < 5) return (this.zipClientOK = true)
    },
  },

  mounted() {},

  async created() {
    this.agentFor = localStorage.getItem("agent_for")
    //this.fetchInsurers()
  },
  methods: {
    async fetchAgencyProducts(typology) {
      console.log(this.radios)
      this.productsAgency = []

      let agencyId = localStorage.getItem("agency_id")
      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(`${config.apiUri}/agencies/${agencyId}/products`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })
        const data = await response.json()

        data.products.forEach(element => {
          if (typology == "Particulier" && parseInt(element.particular) === 1) {
            this.productsArray.push(element)
          }
          if (typology === "Professionnel" && parseInt(element.professionnal) === 1) {
            this.productsArray.push(element)
          }
        })
        console.log(this.productsArray)
      } catch (e) {
        console.error(e)

        this.$toast.error("Impossible d'accéder aux données.\nMerci de réessayer ultérieurement.", {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    saveDate() {
      this.dateODR = moment(this.dateODR).format("DD/MM/YYYY")
      console.log(this.dateODR)
    },
    showErrorIcon(insurer) {
      this.$set(insurer, "showErrorIcon", true)
    },
    getTodayFormatFile() {
      const d = new Date()
      let day
      let month
      let year = d.getFullYear()

      if (d.getDate() < 10) {
        day = "0" + d.getDate()
      } else {
        day = d.getDate()
      }

      month = d.getMonth() + 1
      if (month < 10) {
        month = "0" + month
      } else {
        month = month
      }

      return day + "-" + month + "-" + year
    },
    getToday() {
      const d = new Date()
      let day
      let month
      let year = d.getFullYear()

      if (d.getDate() < 10) {
        day = "0" + d.getDate()
      } else {
        day = d.getDate()
      }

      month = d.getMonth() + 1
      if (month < 10) {
        month = "0" + month
      } else {
        month = month
      }

      return day + "/" + month + "/" + year
    },
    validateFormClientInformations() {
      this.validityFormClientInformations = true
      if (this.$refs.formClientInformations.validate()) {
        this.goToStep4()
      }
    },
    async fetchInsurersByProductName() {
      this.insurersNameForProduct = []
      this.insurerSelected = []
      this.nameOfProduct = []

      // Make the value of productNameList in nameOfProduct for show more easily the product name in Mandat etude
      this.productNameList.forEach(nom => {
        this.nameOfProduct.push(nom.name)
      })

      console.log(this.nameOfProduct)

      let agent = localStorage.getItem("agent_for")
      let idAgency = localStorage.getItem("agency_id")

      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(`${config.apiUri}/partners/${idAgency}`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })
        const data = await response.json()

        data.partners.forEach(element => {
          this.insurersNameForProduct.push({
            name: element.name,
            website_url: element.website_url,
          })
        })
        // Select Gan Assurance by default
        // this.insurersNameForProduct.forEach(insurer => {
        //   if (insurer.name === agent) {
        //     this.insurerSelected.push(insurer.name)
        //   }
        // })
      } catch (e) {
        console.log(e)
      }
    },

    pdfMake() {
      var arrayTemporary = []
      //* Footer du pdf généré
      var footerText =
        "La SARL ANAVEL, société au capital social de 446 226.68,00€, agit en qualité d’agent général d’assurance GAN immatriculée auprès de l’ORIAS sous le numéro 12 065 563 (www.orias.fr) et est soumise à l’Autorité de Contrôle Prudentiel et de Résolution (ACPR) 4 place de Budapest, CS 92459 75436 Paris cedex 09. La SARL ANAVEL est soumise à une obligation contractuelle d’exclusivité avec GAN ASSURANCES et est autorisée à réaliser des opérations de courtage accessoires. La SARL ANAVEL ne dispose pas de lien financier avec GAN ASSURANCES.Numéro de RCS: 538 591 439 ; Garantie Financière et assurances de Responsabilité Civile Professionnelle conformes au code des assurances (contrat numéro 45880, chez l’assureur CGPA). En cas de réclamation, contactez l’agence au numéro suivant : 02 99 73 00 27 ou par mail : contact@anavel.bzh Médiateur : www.mediateur-geia.fr. Par voie électronique, à l’adresse suivante : contact@mediateur-geia.fr "
      this.studyItems.forEach((element, index) => {
        var dd = {
          info: {
            title: `Mandat-Etude-${element.name}-${this.getTodayFormatFile()}`,
          },
          footer: function (currentPage, pageCount) {
            if (currentPage < 2 || currentPage > 2) {
              return {
                height: 300,
                // margin: [10, 10, 10, 10],
                // columns: [
                //   { text: footerText, alignment: "left", fontSize: 5 },
                //   { text: pageCount, fontSize: 10 },
                // ],
                margin: [10, 10, 10, 10],

                table: {
                  widths: ["*", "auto"],

                  body: [
                    [
                      {
                        text: footerText,
                        fontSize: 5,
                        alignment: "justify",
                      },
                      {
                        text: `${currentPage}/${pageCount}`,
                        fontSize: 7,
                      },
                    ],
                  ],
                },
                layout: "noBorders",
              }
            } else {
              return ``
            }
          },
          content: [
            //? information sur notre proposition d’offre de services
            {
              alignment: "center",
              image: imageData.anavel_logo,
              width: 100,
            },
            "\n",
            "\n",
            "\n",
            "\n",
            {
              alignment: "justify",
              columns: [
                {
                  //! A changer plus tard dynamiquement en fonction du nom de l'entreprise ayant l'abonnement
                  //! à Mes Services Assurances
                  text: `Anavel\n 1 Avenue des prunus\n35270 Combourg`,
                },
              ],
            },
            "\n",
            {
              columns: [
                {},
                {},
                {
                  text: `${
                    this.radios === "Particulier"
                      ? `${this.genderClient} ${this.lastnameClient} ${this.firstnameClient}\r${this.streetClient}\r
                    ${this.zipClient} ${this.cityClient}`
                      : `${this.pappersInformations[0].nom_entreprise}\r
                    ${this.pappersInformations[0].siege.adresse_ligne_1}\r
                    ${this.pappersInformations[0].siege.code_postal} ${this.pappersInformations[0].siege.ville}`
                  }
                    `,
                },
              ],
            },
            "\n",
            "\n",
            "\n",
            "\n",
            {
              text: `Objet : information sur notre proposition d’offre de services \n`,
              bold: true,
            },
            "\n",

            "\n",
            {
              text: `Vous nous avez sollicité pour vous accompagner dans l’étude de vos risques d’entreprise et dans l’élaboration d’un programme d’assurance en cohérence avec vos besoins.`,
              style: "test",
            },
            "\n",
            {
              text: `A cette fin, nous avons dans un premier temps sollicité comme notre mandat nous l’impose, la compagnie GAN ASSURANCES afin de vous proposer une couverture adéquate. Néanmoins, les réponses apportées par cette dernière ne peuvent retenir votre attention.\n`,
              style: "test",
            },
            "\n",
            {
              text: `Pour ce faire, vous nous avez demandé de trouver une solution auprès de nos autres partenaires assureurs dans le cadre de notre activité de courtage accessoire.\n`,
              style: "test",
            },
            "\n",
            {
              text: `A cette fin, nous vous remercions de nous avoir confié les mandats correspondants.`,
              style: "test",
            },
            "\n",
            {
              text: `${
                this.radios === "Particulier"
                  ? `Veuillez agréer ${this.genderClient} ${this.lastnameClient} ${this.firstnameClient}, l’expression de nos salutations respectueuses.`
                  : `Veuillez agréer ${this.signatory.map(element => {
                      return " " + element.civility + ". " + element.name
                    })}, l’expression de nos salutations respectueuses.`
              }`,
              style: "test",
            },
            "\n",
            "\n",
            "\n",
            "\n",
            "\n",
            "\n",
            "\n",
            "\n",
            "\n",
            {
              alignment: "justify",
              columns: [
                {},
                {},
                {
                  text: `L'équipe Anavel`,
                  style: "test",
                },
              ],
              pageBreak: "after",
            },

            //? Ordre de placement en courtage

            "\n",
            "\n",
            "\n",
            "\n",

            this.studyMake(element, index),
          ],
        }
        pdfMake.createPdf(dd).getDataUrl(function (outDoc) {
          document.getElementById(`${element.name}`).src = outDoc
          arrayTemporary.push({
            content: outDoc,
            filename: dd.info.title,
          })
        })
        this.studies.push(dd.info.title)
      })
      setTimeout(() => {
        this.arrayPDF = arrayTemporary
      }, 1000)
    },

    sendmail() {
      var dataPDF = []
      this.arrayPDF.forEach(elem => {
        dataPDF.push(elem.content)
      })
      // console.log(this.arrayPDF)
      try {
        emailjs
          .send("service_5p49t7p", "template_qplvwbh", {
            from_name: "Cabinet Anavel",
            to_email: this.emailClient,
            to_copy_email: this.emailCopy,
            filename: "Mandat d'étude",
            content: dataPDF,
          })
          .then(
            response => {
              this.$toast.info(`Le mail est en cours d'envoi.`, {
                position: "bottom-right",
                timeout: 3000,
              })
              this.$toast.success(`Le mail à été envoyé avec succès.`, {
                position: "bottom-right",
                timeout: 3000,
              })
            },
            error => {
              this.$toast.error(`une erreur est survenue.\n${error.text}`, {
                position: "bottom-right",
                timeout: 3000,
              })
            },
          )
        this.dialogEmail = false
      } catch (error) {
        console.log(error)
      }
    },

    async fetchDataInsurer() {
      this.saveDate()
      this.insurerSelected.forEach(select => {
        this.insurerChoose.push(select.name)
      })
      console.log(this.insurerChoose)
      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        let count = 0
        this.insurerChoose.forEach(async element => {
          const response = await fetch(`${config.apiUri}/insurers?name=${element}`, {
            mode: "cors",
            method: "GET",
            headers: headers,
          })
          const data = await response.json()
          //console.log(data)
          data.forEach(elem => {
            this.studyItems.push({
              id: elem.partnerId,
              name: elem.name,
              url: elem.website_url,
              city: elem.city,
              street: elem.street,
              zip: elem.zip,
            })
          })
          count++
          if (count == this.insurerSelected.length) {
            this.pdfMake()
          }
        })
      } catch (e) {
        console.log(e)
      }
    },

    studyMake(element, index) {
      let agency = localStorage.getItem("agency")
      let agency_adress = "1 Avenue du 41ème régiment d’infanterie 35000 Rennes"

      let mandatEtude = []
      mandatEtude.push(
        "\n",
        "\n",
        "\n",
        {
          alignment: "justify",
          columns: [
            {
              text: `${
                this.radios === "Particulier"
                  ? `${this.genderClient} ${this.lastnameClient} ${this.firstnameClient}\r${this.streetClient}\r
                  ${this.zipClient} ${this.cityClient}`
                  : `${this.pappersInformations[0].nom_entreprise}\r
                  ${this.pappersInformations[0].siege.adresse_ligne_1}\r
                  ${this.pappersInformations[0].siege.code_postal} ${this.pappersInformations[0].siege.ville}`
              }
                  `,
            },
          ],
        },
        "\n",
        {
          columns: [
            {},
            {},
            {
              text: `${element.name}\r
                  ${element.street}\r
                  ${element.zip} ${element.city}`,
            },
          ],
        },
        "\n",
        "\n",
        "\n",
        "\n",
        {
          text: `${
            this.mandatChoose === "ME"
              ? `Objet : Mandat d'étude \n`
              : this.mandatChoose === "MEP"
              ? `Objet : Mandat d'étude et de placement \n`
              : this.mandatChoose === "ODR"
              ? `Objet : Ordre de remplacement avec gestion, contrat: ${this.nameOfProduct}, N°${this.numPolice} \n`
              : `Eurreur`
          } `,
          bold: true,
        },
        "\n",
        {
          text: `${
            this.radios === "Particulier" && this.mandatChoose === "ME"
              ? `Je soussigné, ${this.genderClient} ${this.lastnameClient} ${this.firstnameClient} mandate SARL Anavel, 1 Avenue du 41ème régiment d’infanterie 35000 Rennes pour procéder à l’étude des risques dont vous trouverez les détails ci-dessous : `
              : this.radios === "Particulier" && this.mandatChoose === "MEP"
              ? `Je soussigné, ${this.genderClient} ${this.lastnameClient} ${this.firstnameClient} mandate SARL Anavel 1 Avenue du 41ème régiment d’infanterie 35000 Rennes pour procéder à l’étude et au placement des risques dont vous trouverez les détails ci-dessous : `
              : this.mandatChoose === "ODR"
              ? `Nous vous prions de bien vouloir prendre note que nous entendons transférer la police ci-dessus pour sa prochaine échéance, sauf erreur de notre part au ${this.dateODR}.
              D’autre part, nous avons l’honneur de vous faire savoir que nous mandatons la société de courtage d’assurance :\n
              SARL Anavel 1 Avenue du 41ème régiment d’infanterie 35000 Rennes\n

              à l’exclusion de tout autre intermédiaire, pour procéder :
                -	à compter du ${this.dateODR} à la gestion et à toute modification utile de cette même police,
                -	à l’occasion de sa résiliation, à son remplacement.

              Le présent ordre annule et remplace tous ceux qui auraient pu être donnés antérieurement.

              Nous vous prions d’agréer, Madame, Monsieur, l’expression de nos salutations distinguées.`
              : `${this.signatory.length > 1 ? `Nous soussignons` : `Je soussigné`}, ${this.signatory.map(element => {
                  return " " + element.civility + ". " + element.name
                })}, ${this.signatory.length > 1 ? `dirigeants` : `dirigeant`} de ${
                  this.pappersInformations[0].nom_entreprise
                } mandate SARL Anavel 1 Avenue du 41ème régiment d’infanterie 35000 Rennes pour procéder à l’étude et au placement des risques dont vous trouverez les détails ci-dessous : `
          }`,
          style: "test",
        },
        {
          text: `${
            this.mandatChoose != "ODR"
              ? this.nameOfProduct.map(
                  element => {
                    return "\n  -   " + element + ""
                  }, //! SI ON MET UN < \n > après 'element' ça prend bcp de place et risque de prendre 3 PAGES !!!!
                )
              : `
            `
          }`,
          style: "test",
        },
        "\n",
        {
          text: `${
            this.mandatChoose === "ODR"
              ? ``
              : `Le présent mandat annulant tout ordre qui aurait pu être donné antérieurement.`
          }`,
          style: "test",
        },
        "\n",
        "\n",
        "\n",
        "\n",
        "\n",
        "\n",
        "\n",
        {
          alignment: "justify",
          columns: [
            {},
            {},
            {
              text: `${
                this.mandatChoose === "ODR"
                  ? `Fait à ${
                      this.radios === "Particulier"
                        ? `${this.cityClient}`
                        : `${this.pappersInformations[0].siege.ville}`
                    }\r le : ${this.getToday()}\r`
                  : `A ${
                      this.radios === "Particulier"
                        ? `${this.cityClient}`
                        : `${this.pappersInformations[0].siege.ville}`
                    }\r le : ${this.getToday()}\r`
              }
                ${this.signatory
                  .map(element => {
                    return element.name + "\n"
                  })
                  .join("")}`,
              style: "test",
            },
          ],
        },
      )
      //})
      return mandatEtude
    },
    upRecommandAmount() {
      this.recommandAmount++
    },
    downRecommandAmount() {
      this.recommandAmount--
    },

    async testMerger() {
      for (let i = 0; i < this.insurerChoose.length; i++) {
        await merger.add(document.getElementById(`${this.insurerChoose[i]}`).src)
        await merger.save(`Mandat-etude-${this.insurerChoose[i]}-${this.getTodayFormatFile()}`)
        merger = new PDFMerger()
      }
    },

    async MergeForEmail() {
      this.sendmail()
    },

    chooseTypology(typology) {
      typology = typology.toLowerCase()
      typology = typology.charAt(0).toUpperCase() + typology.slice(1)
      this.radios = typology
      this.nextStep(2)
    },
    chooseMandat(md) {
      console.log(md)
      this.mandatChoose = md

      this.nextStep(1)
    },
    goToStep2() {
      this.fetchPappers(this.siret)
      //this.fetchJson()
    },
    goToStep4() {
      this.userState = "courtage"

      this.fetchAgencyProducts(this.radios)

      this.nextStep(3)
    },
    // ? Method to initialize array of cityItems empty
    initializeCity() {
      this.cityItems = []
    },
    // ? Method to upgdate progressbar value
    updateProgressBarUp() {
      this.progressionPdfProgressBar += 14.28571428571429
    },
    updateProgressBarDown() {
      this.progressionPdfProgressBar -= 14.28571428571429
    },
    // ? Method to get all insurers in Eole API
    async fetchInsurers() {
      let idAgency = localStorage.getItem("agency_id")
      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(`${config.apiUri}/partners/${idAgency}`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })
        const data = await response.json()

        data.partners.forEach(element => {
          this.statesInsurence.push(element.name)
          this.arrayChoiceInsurence.push(element.name)
        })
      } catch (e) {
        console.log(e)
      }
    },
    // ? Method to get products by insurerName
    async fetchAllProducts() {
      this.productsArray = []
      try {
        const agent = localStorage.getItem("agent_for")
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(`${config.apiUri}/products?clientTypology=${this.radios}&agent=${agent}`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })
        const data = await response.json()

        data.products.forEach(element => {
          let icon = ""
          switch (element.productName) {
            case "Automobile":
              icon = "fa-solid fa-car fa-5x"
              break
            case "Moto/Scooter":
              icon = "fa-solid fa-motorcycle fa-5x"
              break
            case "Emprunteur":
              icon = "fa-solid fa-building-columns fa-5x"
              break
            case "Santé":
              icon = "fa-solid fa-notes-medical fa-5x"
              break
            case "Multirisque Habitation":
              icon = "fa-solid fa-house-crack fa-5x"
              break
            default:
              icon = "fa-solid fa-eye-slash fa-5x"
              break
          }
          this.productsArray.push({
            name: element.productName,
            icon: icon,
          })
        })
      } catch (e) {
        console.log(e)
      }
    },

    addProduct(product) {
      if (this.productNameList.includes(product)) {
        this.productNameList.splice(this.productNameList.indexOf(product), 1)
      } else {
        this.productNameList.push(product)
      }
    },

    // ? Method to get city by zip code using an API (https://github.com/BaseAdresseNationale/codes-postaux)
    fetchCityByZip(zip) {
      const codesPostaux = require("codes-postaux")
      this.initializeCity()
      if (this.zipClient.length === 5) {
        try {
          var data = codesPostaux.find(zip)
          for (let index = 0; index < data.length; index++) {
            this.cityItems.push(data[index].nomCommune)
          }

          this.successSelectCity = true
          this.successSelectCityMessage = `Nous avons trouvé ${data.length} villes ou communes ayant comme code postal ${zip}`
        } catch (e) {
          this.errorSelectCity = true
          this.errorSelectCityMessage = "Une erreur est survenue"
        }
      } else {
        this.errorSelectCity = true
        this.errorSelectCityMessage = "Le code postal doit être composé de 5 chiffres"
      }
    },

    // ? Method to get informations using a PAPPERS API (https://api.pappers.fr/)
    async fetchPappers(siret) {
      this.pappersInformations = []
      this.loadingData = true

      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(
          `https://api.pappers.fr/v2/entreprise?api_token=${config.tokenPappers}&siret=${siret}`,
          {
            mode: "cors",
            method: "GET",
            headers: headers,
          },
        )

        if (response.status === 400) {
          this.siretNotFound = true
          this.siretNotFoundMessage = "Impossible de trouver le siret que vous avez renseigné, merci de réessayer"
        } else {
          this.siretNotFound = false
          this.siretNotFoundMessage = ""
          let data = await response.json()

          if (data.representants[0].personne_morale === true) {
            let responseSiren = await fetch(
              `https://api.pappers.fr/v2/entreprise?api_token=${config.tokenPappers}&siren=${data.representants[0].siren}`,
              {
                mode: "cors",
                method: "GET",
                headers: headers,
              },
            )
            let dataSiren = await responseSiren.json()

            this.pappersInformations.push(data)
            this.pappersInformations[0].representants = []
            dataSiren.representants.forEach(ceo => {
              if (ceo.qualite === "Gérant") {
                this.pappersInformations.forEach(element => {
                  element.representants.push(ceo)
                })
              }
            })
          } else {
            this.pappersInformations.push(data)
          }
          this.dataPappers = true
        }
        this.loadingData = false
      } catch (e) {
        console.log(e)
      }
    },

    deleteProduct: function (productKey) {
      this.$delete(this.productNameList, productKey)
    },

    deleteInsurer: function (insurerKey) {
      this.$delete(this.insurerSelected, insurerKey)
    },

    // ? Method to go in next step of steppers
    nextStep(n) {
      if (n === this.steps) {
        this.e1 = 1
        this.updateProgressBarUp()
      } else {
        this.e1 = n + 1
        this.updateProgressBarUp()
      }
    },
    // ? Method to go in last step of steppers
    lastStep(n) {
      this.e1 = n - 1
      this.resume.splice(this.e1 >= 3 ? this.e1 - 2 : this.e1 - 1, 1)
      this.updateProgressBarDown()
    },
    close() {
      this.dialog = false
      this.dialog_assurance = false
    },
  },
})
</script>
<style lang="scss">
.mullerFont {
  font-family: muller;
  font-size: 1rem;
}

.card-title {
  font-size: 1.2rem;
  /* ajustez la taille de la police selon vos besoins */
  text-align: center;
  margin-top: 10px;
}

.card-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.row-study {
  max-width: 450px;
}

.title {
  color: #fcc03c !important;
}

.no-padding {
  padding: 0 !important;
}

.no-shadow {
  box-shadow: none !important;
}

.v-switch {
  color: red;
}

.v-slider {
  font-size: 10px;
}

.transparent-bg {
  background-color: transparent !important;
}
</style>
